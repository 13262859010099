import axios from 'axios'
import { CONFIG } from '../utils/config'
import { Message } from 'element-ui'

// axios 相关配置
const instance = axios.create({
  timeout: 10000
})
instance.interceptors.request.use(aconfig => {
  let accessToken = JSON.parse(localStorage.getItem(`access_token:${CONFIG.CLIENT_IDS.ADMIN}`)).accessToken
  accessToken
    ? aconfig.headers['Authorization'] = `Bearer ${accessToken}`
    : ''
  return aconfig
})
instance.interceptors.response.use(
  response => {
    return response
  },
  error => {
    Message.error('网络错误')
    return Promise.reject(error)
  }
)

// 定义请求方法
function $get (url, data = {}) {
  return instance.get(url, { params: data })
}
function $post (url, data = {}) {
  return instance.post(url, data)
}

class Services {
  oss = {
    upload: data => $post(`${CONFIG.BASE_URL}/v1/oss/`, data) // 上传图片到 OSS
  }
  markets = {
    items: data => $post(`${CONFIG.BASE_URL}/v2/users/markets/search-loc`, data), // 获取机构列表
    add: data => $post(`${CONFIG.BASE_URL}/v2/users/markets/add`, data) // 新增机构
  }
  shops = {
    items: data => $post(`${CONFIG.BASE_URL}/v2/users/companies/search-loc`, data), // 获取店铺列表
    add: data => $post(`${CONFIG.BASE_URL}/v2/users/companies/add`, data) // 新增店铺
  }
  apply = {
    add: data => $post(`${CONFIG.DEMO_URL}/v1/gov_data/fsc/apply/info`, data), // 提交申报
    items: data => $get(`${CONFIG.DEMO_URL}/v1/gov_data/fsc/apply/info`, data), // 查询申报列表
    update: data => $post(`${CONFIG.DEMO_URL}/v1/gov_data/fsc/apply/approval`, data) // 查询申报列表
  }
  recipes = {
    catalog: data => $get(`${CONFIG.BASE_URL}/v1/catalog-go/tags`, data) // 获取菜品分类
  }
}

export const services = new Services()
