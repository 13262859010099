const routers = [
  {
    path: '/onecode/markets',
    name: 'markets',
    meta: {
      title: '机构列表'
    },
    component: () => import('../views/onecode/markets/list/Index')
  },
  {
    path: '/onecode/shops',
    name: 'shops',
    meta: {
      title: '店铺列表'
    },
    component: () => import('../views/onecode/shops/items/Index')
  },
  {
    path: '/onecode/apply',
    name: 'apply',
    meta: {
      title: '申请列表'
    },
    component: () => import('../views/onecode/markets/apply/items/Index')
  },
  {
    path: '/onecode/apply/details',
    name: 'applyDetails',
    meta: {
      title: '申请详情'
    },
    component: () => import('../views/onecode/markets/apply/details/Index')
  },
  {
    path: '/onecode/recipes/catalog',
    name: 'recipesCatalog',
    meta: {
      title: '菜品分类'
    },
    component: () => import('../views/onecode/recipes/catalog/Index')
  }
]

export default { routers }
