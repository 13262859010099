export const CONFIG = {
  BASE_URL: 'https://testing.smartracing.cn', // 溯源 api 地址
  DEMO_URL: 'https://demo.smartracing.cn', // 益农 api 地址
  // 用户目录2.0 登录回调路径
  REDIRECT_URIS: [
    'http://localhost:8080/#/login',
    'https://super.testing.smartracing.cn/#/login'
  ],
  // 项目 ID
  CLIENT_IDS: {
    ADMIN: '5f912df83566dfc22c2edafe',  // 食安云运营后台
    ONECODE_ADMIN: '5dfc4360bbd4d0c97f592218', // 食神通大掌柜
  }
}
