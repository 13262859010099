import Vue from 'vue'

import 'normalize.css'
import '../assets/css/reset.scss'

import { services } from '../api/index'
Vue.prototype.$api = services
import { CONFIG } from './config'
Vue.prototype.$config = CONFIG

// 引入用户目录 2.0
import StApp from 'st-ud-sdk/dist/app'
let st = new StApp({
  appID: '', // 微信公众号ID或支付宝公众号ID
  clientID: CONFIG.CLIENT_IDS.ADMIN, // 项目ID
  baseURL: CONFIG.BASE_URL + '/v2/users', // api 接口地址
  mode: 'dark', // 显示风格 默认-普通模式 dark-暗黑模式
  redirectUri: CONFIG.REDIRECT_URIS[1] // 获取授权码回调地址
})
Vue.prototype.st = st

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '../assets/css/base.scss'
Vue.use(ElementUI)
