import Vue from 'vue'
import Vuex from 'vuex'
import { services } from '../api/index'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: {}, // 用户信息
    marketCount: 0, // 市场总数
    marketItems: [], // 市场列表
    shopCount: 0, // 店铺总数
    shopItems: [] // 店铺列表
  },
  mutations: {
    SET_USER_INFO (state, data) {
      state.userInfo = data
      localStorage.setItem('user_info', JSON.stringify(data))
    },
    GET_MARKET_ITEMS (state, data) {
      state.marketCount = data.count
      state.marketItems = data.marketItems
    },
    GET_SHOP_ITEMS (state, data) {
      state.shopCount = data.count
      state.shopItems = data.shopItems
    }
  },
  actions: {
    // 保存用户信息
    setUserInfo (context, data) {
      context.commit('SET_USER_INFO', data)
    },

    // 获取市场列表
    getMarketItems (context, data) {
      services.markets.items(data).then(res => {
        if (res.status === 200) {
          context.commit('GET_MARKET_ITEMS', {
            count: res.data.count,
            marketItems: res.data.data
          })
        }
      })
    },

    // 获取店铺列表
    getShopItems (context, data) {
      services.markets.items(data).then(res => {
        if (res.status === 200) {
          context.commit('GET_MARKET_ITEMS', {
            count: res.data.count,
            marketItems: res.data.data
          })
        }
      })
    }
  },
  modules: {
  }
})
