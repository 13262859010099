<template>
  <el-container class="layout">
    <el-header>
      <h1>食安云运营后台</h1>
      <div class="header-main">
        <ul class="nav-main">
          <li
            v-for="item in navs"
            :key="item.type"
            :class="navIndex === item.type ? 'active' : ''"
            @click="navChange(item)">
            {{ item.title }}
          </li>
        </ul>
        <div class="user-nav">
          <el-dropdown
            placement="bottom"
            @command="handleCommand">
            <div class="user-info">
              <div class="icon">
                <img src="../../assets/images/user-icon.png" alt="">
              </div>
              <p>{{ userInfo.name || '' }}</p>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="logout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </el-header>
    <el-container>
      <el-aside class="side-bar">
        <div class="menu">
          <el-menu
            :router="true"
            :unique-opened="true"
            :default-active="$route.path">
            <el-submenu
              :index="menu.index"
              v-for="menu in menus"
              :key="menu.index">
              <template slot="title">
                <i :class="menu.icon">{{ menu.title }}</i>
              </template>
              <el-menu-item
                :index="subMenu.path"
                v-for="subMenu in menu.items"
                :key="subMenu.name">
                <p>{{ subMenu.title }}</p>
              </el-menu-item>
            </el-submenu>
          </el-menu>
        </div>
      </el-aside>
      <el-main>
        <div class="main">
          <router-view />
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import menus from '../../utils/menu'
import _ from 'lodash'

export default {
  data () {
    return {
      navIndex: 0,
      navs: [
        { type: 0, name: 'home', title: '首页' },
        { type: 1, name: 'onecode', title: '食神通' }
      ],
      menus: [],

      userInfo: {}
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    // 初始化
    init () {
      let path = this.$route.path.split('/')[1]
      let n = _.findIndex(this.navs, item => item.name === path)
      n >= 0
        ? this.navIndex = n
        : this.navIndex = 0
      this.menus = menus[this.navIndex].items
      this.userInfo = JSON.parse(localStorage.getItem('user_info'))
    },

    // 切换主类别
    navChange (item) {
      this.navIndex = item.type
      this.menus = menus[this.navIndex].items
    },

    // 点击用户菜单
    handleCommand (command) {
      if (command === 'logout') {
        this.st.user.logout()
        this.$store.dispatch('setUserInfo', {})
        this.$router.push({ name: 'login' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "./index";
</style>
